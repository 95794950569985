import React, { useState } from 'react'
import {Link} from 'gatsby'
import shape1 from '../../assets/images/shape/shape1.svg'
import shape2 from '../../assets/images/shape/shape2.png'
import shape3 from '../../assets/images/shape/shape3.png'
import { useRef } from 'react'
import { useEffect } from 'react'



const ServicesPageBanner = ({pageTitle, homePageUrl, homePageText, activePageText}) => {

    const [dropDownActive,setDropDownActive] = useState(false)
    const elementRef = useRef(null);
    const iconRef = useRef(null);

    

    useEffect(() => {
        function handler(event) {

            if((!elementRef.current?.contains(event.target))) {
                setDropDownActive(false)
            }

        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, [])

    return (
        <div className="page-title-area1">
            <div className="container">
                <div className="page-title-content">
                    <h2>{pageTitle}</h2>
                    <ul className='position-relative'>
                        <li>
                            <Link to={homePageUrl}>
                                {homePageText}
                            </Link>
                        </li>

                        <li ref={elementRef}  className='cursor-pointer position-relative' onClick={() => setDropDownActive(!dropDownActive)}>{activePageText} {dropDownActive?'':<i ref={iconRef} className='products-cDown-icon hide-on-lg bx bx-chevron-down'></i>} </li>

                        {
                            dropDownActive ?
                          <div className="products-dropdown hide-on-lg ">
                                     <p className="">
                                         <Link
                                             to="blood-bank-software"
                                             activeClassName="active"
                                             className="nav-link"
                                         >
                                             Blood Banking Management Information System
                                         </Link>
                                     </p>
                                     <p className="">
                                         <Link
                                             to="/products/patient-blood-management"
                                             activeClassName="active"
                                             className="nav-link"
                                         >
                                             Patient Blood Management System
                                         </Link>
                                     </p>
                                     <p className="">
                                         <Link
                                             to="/products/isbt-128-labeling-system"
                                             activeClassName="active"
                                             className="nav-link"
                                         >
                                             ISBT 128 Labelling System
                                         </Link>

                                     </p>
                                     {/* <p className="">
                                         <Link
                                             to="/narro"
                                             activeClassName="active"
                                             className="nav-link"
                                         >
                                             Patient Registration Sytem
                                         </Link>
                                     </p>
                                     <p className="">
                                         <Link
                                             to="/confio"
                                             activeClassName="active"
                                             className="nav-link"
                                         >
                                             Bedside Specimen Collection Sytem
                                         </Link>
                                     </p> */}
                                     <p className="">
                                         <Link
                                             to="/products/electronic-document-management-system"
                                             activeClassName="active"
                                             className="nav-link"
                                         >
                                             Electronic Document Management System
                                         </Link>
                                     </p>
                                     <p className="">
                                         <Link
                                             to="/products/workforce-management-software"
                                             activeClassName="active"
                                             className="nav-link"
                                         >
                                             Workforce Management Software
                                         </Link>
                                     </p>
                                     <p className="">
                                         <Link
                                             to="/products/quality-control-tracking"
                                             activeClassName="active"
                                             className="nav-link"
                                         >
                                             Quality Control Management System
                                         </Link>
                                     </p>
                         </div>:""
                        }
                        
                    </ul>
                </div>
            </div>

            <div className="shape-img1">
                <img src={shape1} alt="banner" />
            </div>
            <div className="shape-img2">
                <img src={shape2} alt="banner" />
            </div>
            <div className="shape-img3">
                <img src={shape3} alt="banner" />
            </div>
        </div>
    )
}

export default ServicesPageBanner;

